import { render, staticRenderFns } from "./CreateHolidayModal.vue?vue&type=template&id=47e0eca4&scoped=true"
import script from "./CreateHolidayModal.vue?vue&type=script&lang=js"
export * from "./CreateHolidayModal.vue?vue&type=script&lang=js"
import style0 from "./create-holiday-modal.scss?vue&type=style&index=0&id=47e0eca4&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47e0eca4",
  null
  
)

export default component.exports