<template>
  <div class="holiday-create" :class="{ 'active': show }">
    <n-loader :loading="$var('load')" />
    <n-card class="holiday-card">
      <div class="card-top">
        <h4>Добавить день</h4>
        <n-button flat @click="closeModal">X</n-button>
      </div>

      <form @submit.prevent="handleSubmit">
        <div class="form-inner">
          <c-datepicker
            v-model="dateHoliday" 
            class="my-date-picker" 
            lang="rus" 
            position="left" 
            placeholder="Выберите день"
          />
          <div class="holiday-create__buttons">
            <n-button type="submit">Создать</n-button>
          </div> 
        </div>
      </form>
    </n-card>
  </div>
</template>

<script>
export default {
  name: 'CreateholidayModal',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    // companyId: {
    //   type: String,
    //   required: true,
    // },
  },
  data() {
    return {
      dateHoliday: null,
      // holidayTypes: [],
      // holidayType: {},
      // calculationTypes,
      // calculationType: {},
      // workDay: '',
    }
  },
  computed: {
    // isDisable() {
    //   return Object.keys(this.holidayType).length === 0 || Object.keys(this.calculationType).length === 0; 
    // }
  },
  mounted() {
    // this.fetchholidayTypes()
  },

  methods: {
    closeModal() {
      this.$emit('update:show', false)
    },
    handleSubmit() {
      this.$var('load', true)
      
      const data = {
        date: $n.formatDate(this.dateHoliday),
      }
      $api.holidays.create(data)
        .then((response) => {
          if (response.status === 200) {
            this.closeModal();
            this.$emit('refreshHolidays');
            $app.ui.notify("Успешно добавлена", 'success')
          }
          this.$var('load', false)
        })
        .catch((error) => {
          this.$var('load', false)
          console.error('An error occurred.');
          $app.ui.notify("Ошибка, попробуйте еще раз", 'danger')
        })
    }
  }
}
</script>

<style scoped src="./create-holiday-modal.scss" lang="scss"></style>
