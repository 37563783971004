<!-- eslint-disable no-multiple-empty-lines -->
<!-- eslint-disable vue/multiline-html-element-content-newline -->
<template>
  <div class="schedule-create" :class="{ 'active': show }">
    <n-loader :loading="$var('load')" />
    <n-card class="schedule-card">
      <div class="card-top">
        <n-button flat @click="closeModal">X</n-button>
      </div>

      <form @submit.prevent="handleSubmit">
        <div class="schedule-create__body">
          <n-select title="График" :data="scheduleTypes" :value.sync="scheduleType" />
          <n-select title="Тип" :data="calculationTypes" :value.sync="calculationType" />
          <n-input 
            v-model="workDay" 
            title="Кол-во рабочих дней" 
            :disabled="calculationType.value !== 'CONST'" 
            placeholder="Введите кол-во рабочих дней" />
        </div>

        <div class="schedule-create__buttons">
          <n-button type="submit" :disabled="isDisable">Создать</n-button>
        </div>
      </form>

    </n-card>
  </div>
</template>

<script>
import { calculationTypes } from '../timetable/constants';

export default {
  name: 'CreateScheduleModal',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    companyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      scheduleTypes: [],
      scheduleType: {},
      calculationTypes,
      calculationType: {},
      workDay: '',
    }
  },
  computed: {
    isDisable() {
      return Object.keys(this.scheduleType).length === 0 || Object.keys(this.calculationType).length === 0; 
    }
  },
  mounted() {
    this.fetchScheduleTypes()
  },

  methods: {
    closeModal() {
      this.$emit('update:show', false)
    },
    async fetchScheduleTypes() {
      try {
        const response = await $api.companies.getScheduleTypes()
        this.scheduleTypes = response.data.map((type) => {
          return { title: type, value: type }
        });

      } catch (error) {
        console.error('An error occurred.');
        $app.ui.notify('Ошибка при загрузке типов графиков, обновите страницу.', 'danger')
      }
    },
    handleSubmit() {
      this.$var('load', true)

      const data = {
        scheduleType: this.scheduleType.value,
        workDayConst: this.workDay ? Number(this.workDay) : 0,
        calculationType: this.calculationType.value,
        companyId: Number(this.companyId)
      }

      $api.companies.createSchedule(data)
        .then((response) => {
          if (response.status === 200) {
            this.closeModal();
            this.$emit('refreshSchedule');
          }
          this.$var('load', false)
        })
        .catch((error) => {
          this.$var('load', false)
          $app.ui.notify(error.response.data.message, 'danger')
        })
    }
  }
}
</script>

<style scoped src="./create-schedule-modal.scss" lang="scss"></style>
