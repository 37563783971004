/**
 *
 */

export default class UploadAdapter {
  send
  // The file loader instance to use during the upload.
  loader
  
  constructor(loader) {
    this.send = $api.files.create
    this.loader = loader
  }
  
  upload() {
    return this.loader.file.then((file) => new Promise((resolve, reject) => {
      this.send({ file, }).then((response) => {
        resolve({
          default: response.data.content.src,
        })
      })
    }))
  }
  
  static uploader(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new UploadAdapter(loader)
    }
  }
}
